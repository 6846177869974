import {
  isDesktop,
  isTablet,
  isMobileOnly,
  isMobile,
} from "react-device-detect";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PlayImg from "../../../plc/src/data/icons/play.svg";
import PlayNowImg from "../../../abc/src/data/icons/play-now.svg";
import RacHoverImg from "../../../rac/src/data/img/game-hover.svg";
import ExpandMenuLight from "../../../plc/src/data/img/vertical-expand-menu.svg";
import ExpandMenuDark from "../../../plc/src/data/img/vertical-expand-menu-dark.svg";
import { ReactComponent as DoubleArrowsRight } from "../../../plc/src/data/icon-component/double-arrows-right.svg";
import { ReactComponent as DoubleArrowsLeft } from "../../../plc/src/data/icon-component/double-arrows-left.svg";

/* export const StyledGameLink = styled(Link)`
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
  &:after {
    content: 'maintenance';
    position: relative;
    display: block;
    background: white;
    width: 100%;
    text-align: center;
    top: -20%;
    height: 0;
    color: white;
    font-weight: bolder;
    font-size: 12px;
    text-transform: uppercase;
  }
`;
 */

export const StyledProviderLogo = styled.section`
  margin: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "40px auto"
      : "70px auto"};
  width: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? ""
      : ""};
  max-width: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "1600px"
      : ""};
  border-bottom: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? " 1px solid var(--borderABC)"
      : ""};

  background-color: ${({ background }) => background && "var(--footerBubleBG)"};
  border-radius: ${({ background }) => background && "45px"};

  @media only screen and (max-width: 767px) {
    width: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "100%"
        : ""};
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }
  svg {
    fill: red;
    background-color: red;
  }
`;

export const GameProviderH3 = styled.h3`
  color: ${({ theme }) =>
    theme.skin === "nbc"
      ? "#70328e"
      : theme.skin === "yet"
      ? "#358bb7"
      : theme.skin === "fun" || theme.skin === "yak"
      ? "var(--racProfileMenuCol)"
      : "var(--brandcolor)"};
  font-family: ${({ theme }) => theme.skin === "pub" && "Ancho"};
  font-size: ${({ theme }) => (theme.skin === "pub" ? "1.33rem" : "1.5rem")};
  font-weight: ${({ theme }) => (theme.skin === "pub" ? "400" : "800")};
  padding: 30px;

  @media only screen and (max-width: 767px) {
    margin: 0 20px;
    line-height: 25px;
    font-size: 1.4rem;
  }
`;

export const GameProviderWrapper = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "abc" || props.theme.skin === "nbc"
      ? ""
      : "justify-content-md-center",
}))`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    justify-content: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun"
        ? "center"
        : ""};
    padding-bottom: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun"
        ? "40px"
        : ""};
  }
`;

export const GameProviderCol = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "abc" ||
    props.theme.skin === "nbc" ||
    props.theme.skin === "yet" ||
    props.theme.skin === "fun"
      ? ""
      : "col col-lg-7 col-sm-12 p-0",
}))`
  /* @media (max-width: 767px) {
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media (min-width: 768px) and (max-width: 924px) {
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
  } */
`;

export const GameProviderExtend = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "abc" ||
    props.theme.skin === "nbc" ||
    props.theme.skin === "yet" ||
    props.theme.skin === "fun" ||
    props.theme.skin === "yak"
      ? "justify-content-center"
      : "justify-content-md-between",
}))`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "40px"
      : ""};
  border-right: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "1px solid var(--borderABC)"
      : ""};
  border-left: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "1px solid var(--borderABC)"
      : ""};

  .extra-border {
    :first-child {
      border-left: none;
    }
    :last-child {
      border-right: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "1px solid var(--borderABC)"
          : ""};

      @media (max-width: 767px) {
        border-right: none;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        border-right: none;
      }
    }

    border-left: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak") &&
      !isMobileOnly
        ? "1px solid var(--borderABC)"
        : ""};
  }
  svg {
    fill: var(--newsText);
    width: 120px;
    height: 100px;
    margin: 20px;
    padding: 15px;
    transition: transform 0.4s;
    -webkit-transition: transform 0.4s;
    -moz-transition: transform 0.4s; /* Firefox */
    -ms-transition: transform 0.4s; /* Internet Explorer */
    -o-transition: transform 0.4s;
    animation-delay: 0s;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-name: ${({ test }) => (test ? "scaleAnimation" : "")};
    animation-fill-mode: forwards;
    box-shadow: ${({ test }) =>
      test ? "0 0 11px rgba(146, 146, 146, 0.2)" : ""};
    -webkit-box-shadow: ${({ test }) =>
      test ? "0 0 11px rgba(146, 146, 146, 0.2)" : ""};
    @keyframes scaleAnimation {
      0% {
        transform: scale(1);
        -webkit-transform: scale(1); /* Safari & Chrome */
        -moz-transform: scale(1); /* Firefox */
        -ms-transform: scale(1); /* Internet Explorer */
        -o-transform: scale(1);
      }
      50% {
        transform: scale(1.2);
        -webkit-transform: scale(1.2); /* Safari & Chrome */
        -moz-transform: scale(1.2); /* Firefox */
        -ms-transform: scale(1.2); /* Internet Explorer */
        -o-transform: scale(1.2);
      }
      100% {
        transform: scale(1);
        -webkit-transform: scale(1); /* Safari & Chrome */
        -moz-transform: scale(1); /* Firefox */
        -ms-transform: scale(1); /* Internet Explorer */
        -o-transform: scale(1);
      }
    }

    &:hover {
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0 0 11px rgba(146, 146, 146, 0.2);
      -webkit-appearance: none;
      appearance: none;
      -webkit-box-shadow: 0 0 11px rgba(146, 146, 146, 0.2); /* Safari & Chrome */
      -moz-box-shadow: 0 0 11px rgba(146, 146, 146, 0.2); /* Firefox */
      transform: scale(1.5);
      -webkit-transform: scale(1.5); /* Safari & Chrome */
      -moz-transform: scale(1.5); /* Firefox */
      -ms-transform: scale(1.5); /* Internet Explorer */
      -o-transform: scale(1.5);
      transition: transform 0.2s;
      -webkit-transition: transform 0.2s;
      -moz-transition: transform 0.2s; /* Firefox */
      -ms-transition: transform 0.2s; /* Internet Explorer */
      -o-transition: transform 0.2s;
      padding: 10px;
      filter: blur(0px);
    }
  }

  .customTable {
    width: 100%;
    background-color: #ffffff;
    /* border-collapse: collapse;
    border-width: 1px;
    border-color: #7ea8f8;
    border-style: solid; */
    color: #000000;
  }

  .customTable td,
  table.customTable {
    text-align: center;
    /* border-width: 1px;
    border-color: #7ea8f8;
    border-style: solid; */
    border-left: 1px solid;
    padding: 5px;
  }

  img {
    width: 120px;
    height: 100px;
    margin: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? ""
        : "20px"};
    padding: 15px;
    transition: transform 0.4s;
    -webkit-transition: transform 0.4s;
    -moz-transition: transform 0.4s; /* Firefox */
    -ms-transition: transform 0.4s; /* Internet Explorer */
    -o-transition: transform 0.4s;
    animation-delay: 0s;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-name: ${({ test }) => (test ? "scaleAnimation" : "")};
    animation-fill-mode: forwards;
    box-shadow: ${({ test }) =>
      test ? "0 0 11px rgba(146, 146, 146, 0.2)" : ""};
    -webkit-box-shadow: ${({ test }) =>
      test ? "0 0 11px rgba(146, 146, 146, 0.2)" : ""};
    @keyframes scaleAnimation {
      0% {
        transform: scale(1);
        -webkit-transform: scale(1); /* Safari & Chrome */
        -moz-transform: scale(1); /* Firefox */
        -ms-transform: scale(1); /* Internet Explorer */
        -o-transform: scale(1);
      }
      50% {
        transform: scale(1.2);
        -webkit-transform: scale(1.2); /* Safari & Chrome */
        -moz-transform: scale(1.2); /* Firefox */
        -ms-transform: scale(1.2); /* Internet Explorer */
        -o-transform: scale(1.2);
      }
      100% {
        transform: scale(1);
        -webkit-transform: scale(1); /* Safari & Chrome */
        -moz-transform: scale(1); /* Firefox */
        -ms-transform: scale(1); /* Internet Explorer */
        -o-transform: scale(1);
      }
    }

    &:hover {
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0 0 11px rgba(146, 146, 146, 0.2);
      -webkit-appearance: none;
      appearance: none;
      -webkit-box-shadow: 0 0 11px rgba(146, 146, 146, 0.2); /* Safari & Chrome */
      -moz-box-shadow: 0 0 11px rgba(146, 146, 146, 0.2); /* Firefox */
      transform: scale(1.5);
      -webkit-transform: scale(1.5); /* Safari & Chrome */
      -moz-transform: scale(1.5); /* Firefox */
      -ms-transform: scale(1.5); /* Internet Explorer */
      -o-transform: scale(1.5);
      transition: transform 0.2s;
      -webkit-transition: transform 0.2s;
      -moz-transition: transform 0.2s; /* Firefox */
      -ms-transition: transform 0.2s; /* Internet Explorer */
      -o-transition: transform 0.2s;
      padding: 10px;
      filter: blur(0px);
      background-color: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "var(--bgColorABC)"
          : ""};
      border-left: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "none"
          : ""};
    }
  }

  @media only screen and (max-width: 767px) {
    width: 330px;
    margin: 0px auto;
    text-align: center;
    justify-content: space-between;

    svg {
      width: 70px;
      height: 40px;
      padding: 0;
    }
    img {
      width: 70px;
      height: 40px;
      padding: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 924px) {
    width: 100%;
    justify-content: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "space-between"
        : ""};
  }
`;

export const ProviderList = styled.div`
  fill: var(--newsText);
  width: 120px;
  height: 100px;
  margin: 20px;
  padding: 15px;
  transition: transform 0.4s;

  &:hover {
    cursor: pointer;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 11px rgba(146, 146, 146, 0.2);
    -moz-box-shadow: 0 0 11px rgba(146, 146, 146, 0.2);
    box-shadow: 0 0 11px rgba(146, 146, 146, 0.2);
    transform: scale(1.5);
    transition: transform 0.2s;
    padding: 10px;
    filter: blur(0px);
  }

  @media (max-width: 568px) {
    width: 60px;
    height: 20px;
    padding: 0;
  }

  @media (min-width: 568px) and (max-width: 767px) {
    width: 70px;
    height: 20px;
    padding: 0;
  }
`;

export const LobbyWrapper = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "abc" ||
    props.theme.skin === "nbc" ||
    props.theme.skin === "yet" ||
    props.theme.skin === "fun" ||
    props.theme.skin === "yak"
      ? ""
      : "mb-5",
}))`
  svg {
    padding: ${({ gameLoader }) => gameLoader && "0px 20px"};
    max-width: 1600px;
  }
  padding-top: ${({ gamesCategory }) => gamesCategory && "5rem"};
  flex-direction: column;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "yet"
      ? "var(--mainDescriptionABC)"
      : "var(--lobbybg)"};
  & > div {
    width: ${({ theme }) =>
      (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
      isMobileOnly
        ? ""
        : "1600px"};
    height: auto;
    max-width: 100%;
    padding: ${isTablet ? "1rem" : "40px 0"};
    z-index: 2;

    @media (max-width: 768px) {
      padding: ${isMobileOnly ? "0px 10px 10px 10px" : "10px 20px 0 20px"};
    }
  }

  /* .game-category {
    height: auto;
    max-width: 100%;
    margin-top: 0px;
    &-header {
      width: 100%;
      height: 67px;
    }
  } */

  .btn {
    font-size: 1rem;
    padding: ${({ theme }) =>
      theme.skin === "cac" || theme.skin === "hyc" ? "15px 20px" : "20px"};
    min-width: 170px;
  }
`;

export const GameCategory = styled.div`
  height: auto;
  max-width: 100%;
  margin-top: 0px;

  @media (max-width: 770px) {
    margin-top: 0px;
  }
`;

export const GameCategoryTitle = styled.p`
  font-weight: 600;
  color: ${({ theme }) =>
    theme.skin === "abc"
      ? "#cf1f2c"
      : theme.skin === "pub"
      ? "#9A875D"
      : theme.skin === "rac"
      ? "var(--footerlinks)"
      : "var(--showall)"};
  text-transform: capitalize;
  width: 100%;
  font-size: 24px !important;
  top: ${({ category }) => (category ? "" : "-30px")};

  @media (max-width: 1024px) {
    font-size: 20px !important;
  }
`;

export const GameCategoryContent = styled.div`
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  display: ${({ hide }) => (hide ? "none" : "")};
  margin-bottom: ${({ removeMargin }) => (removeMargin ? "3rem" : "")};
  margin-top: ${({ theme }) =>
    theme.skin === "rac" && (isMobile ? "2rem" : "3rem")};
`;
export const EmptyCategoriy = styled.div`
  max-width: 100%;
  margin-top: 0px;
  padding: 100px 0;
  display: ${({ hide }) => (hide ? "none" : "")};
  text-align: center;
  h2 {
    font-weight: 800;
    color: ${({ theme }) =>
      theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "fun" || theme.skin === "yak"
        ? "#fa2c70"
        : theme.skin === "cac"
        ? "#fdb448"
        : "#ae0005"};
    padding-top: 1rem;
    @media (max-width: 790px) {
      font-size: 1.4rem;
    }
  }
  div {
    font-weight: 800;
    font-size: 1rem;
    color: var(--newsText);
  }
  svg {
    fill: var(--newsText);
    width: 100px;
    padding-top: 1rem;
  }
`;

export const GameCategoryHeader = styled.div`
  width: 100%;
  height: ${({ provider }) => (provider ? "" : isTablet ? "40px" : "45px")};
  /* margin-top: 3rem; */
  text-align: ${({ gameTheme, theme }) =>
    gameTheme ? "left" : theme.skin === "pub" ? "center" : ""};

  @media (max-width: 767px) {
    height: ${({ provider, theme }) =>
      provider ? "" : theme.skin === "abc" ? "25px" : ""};
    p {
      margin-bottom: ${({ theme }) => theme.skin === "abc" && "0"};
    }
  }
`;

export const CategoryTitle = styled.p`
  font-family: ${({ theme }) => theme.skin === "pub" && "Ancho"};
  font-size: ${({ theme }) => (theme.skin === "pub" ? "1.13rem" : "24px")};
  float: ${({ theme }) => theme.skin !== "pub" && "left"};
  font-weight: ${({ theme }) => (theme.skin === "pub" ? "300" : "600")};
  color: ${({ theme }) =>
    theme.skin === "abc"
      ? "#cf1f2c"
      : theme.skin === "rac"
      ? "var(--footerlinks)"
      : "var(--showall)"};
  text-transform: capitalize;
  text-align: center;

  @media (max-width: 770px) {
    font-size: ${({ theme }) => (theme.skin === "abc" ? "16px" : "20px")};
  }
`;

export const ShowAllGames = styled.p`
  float: right;
  font-weight: 600;
  color: ${({ theme }) =>
    theme.skin === "abc"
      ? "#cf1f2c"
      : theme.skin === "rac"
      ? "var(--carouseltext)"
      : "var(--showall)"};
  text-transform: capitalize;
  cursor: pointer;
  font-size: ${({ theme }) => (theme.skin === "pub" ? "0.8rem" : "14px")};
  text-align: center;
  padding-top: ${({ theme }) => theme.skin === "pub" && "1rem"};

  @media (max-width: 770px) {
    font-size: 12px;
  }
`;

export const ShowAllArrow = styled.span`
  margin-left: 10px;
  font-size: 32px;
  font-weight: 400;
  vertical-align: bottom;
  position: relative;
  bottom: 3px;

  @media (max-width: 768px) {
    position: static;
  }
`;

export const GamesGriddy = styled.div.attrs((props) => ({
  className: props.remainder
    ? "grid-container-reverse"
    : props.opened
    ? "grid-container-opened"
    : "grid-container",
}))`
  .first,
  .eight {
    grid-area: ${({ racGameCategorie }) => racGameCategorie && "auto"};
  }
  & > a {
    /* filter: ${({ theme }) => theme.skin === "pub" && `grayscale(100%)`};
    -webkit-filter: ${({ theme }) =>
      theme.skin === "pub" && `grayscale(100%)`}; */
    display: block;
    box-sizing: border-box !important;
    padding: 0 !important;
    margin: 0 !important;
    background-image: ${({ theme }) =>
      theme.skin === "rac"
        ? `url(${RacHoverImg})`
        : theme.skin === "plc"
        ? `url(${PlayImg})`
        : ""};
    background-color: transparent;
    background-size: ${({ theme }) => (theme.skin === "rac" ? "100%" : "50px")};
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      filter: ${({ theme }) => theme.skin === "pub" && `grayscale(100%)`};
      -webkit-filter: ${({ theme }) =>
        theme.skin === "pub" && `grayscale(100%)`};
    }
    source,
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      /*object-fit: cover;*/
    }

    &.maintenance {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
      &:after {
        content: ${({ theme }) =>
          theme.skin !== "plc" ? "'Maintenance'" : "'In Onderhoud'"};
        position: relative;
        display: block;
        background: white;
        width: 100%;
        text-align: center;
        top: -20%;
        height: 0;
        color: white;
        font-weight: bolder;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }

  grid-template-columns: ${({ specialGame }) => specialGame && "100%"};
  grid-template-areas: ${({ specialGame, theme }) =>
    specialGame &&
    theme.skin === "rac" &&
    `"first first second third fourth"
"first first fifth sixth seventh" !important`};

  a:first-child {
    border-top-left-radius: ${({ theme }) =>
      theme.skin === "plc" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc"
        ? "15px"
        : ""};
    picture,
    source,
    img {
      border-top-left-radius: ${({ theme }) =>
        theme.skin === "plc" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak"
          ? "15px"
          : ""};
    }
  }
  a:last-child,
  .bottom-left-corner {
    border-bottom-right-radius: ${({ theme }) =>
      theme.skin === "plc" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak"
        ? "15px"
        : ""};
    picture,
    source,
    img {
      border-bottom-right-radius: ${({ theme }) =>
        theme.skin === "plc" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak"
          ? "15px"
          : ""};
    }
  }
  .last-in-row {
    border-top-right-radius: ${({ theme }) =>
      theme.skin === "plc" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak"
        ? "15px"
        : ""};
    picture,
    source,
    img {
      border-top-right-radius: ${({ theme }) =>
        theme.skin === "plc" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak"
          ? "15px"
          : ""};
    }
  }
  .first-in-last-colon {
    border-bottom-left-radius: ${({ theme }) =>
      theme.skin === "plc" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak"
        ? "15px"
        : ""};
    picture,
    source,
    img {
      border-bottom-left-radius: ${({ theme }) =>
        theme.skin === "plc" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak"
          ? "15px"
          : ""};
    }
  }

  a > picture {
    transition: all 0.2s ease;
    /* object-fit: cover; */
    display: block;
    width: 100%;
    height: 100%;
    /* background: var(--brandcolor); */
    cursor: pointer;

    img {
      transition: all 0.2s ease;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    /* img:before {
      content: "We're sorry, the image below is broken :(";
      display: block;
      margin-bottom: 10px;
    } */

    &:hover {
      opacity: ${({ theme }) => theme.skin !== "pub" && `0.1`};
    }
  }
`;

export const StyleGameLink = styled(Link).attrs((props) => ({
  className:
    (props.maintentnece && " maintenance ") +
    (props.lastInRow && " last-in-row ") +
    (props.bottomLeftCorner && " first-in-last-colon ") +
    (props.bottomRightCorner && " bottom-left-corner "),
}))`
  p {
    display: none;
  }
  ${(props) => {
    if (
      (props.theme.skin === "abc" ||
        props.theme.skin === "nbc" ||
        props.theme.skin === "bnk" ||
        props.theme.skin === "yet" ||
        props.theme.skin === "fun" ||
        props.theme.skin === "cac" ||
        props.theme.skin === "hyc" ||
        props.theme.skin === "yak") &&
      isDesktop
    ) {
      return `
      :hover {
        p {
          display: inline;
        }
        color: transparent;
        width: 100%;
        height: 100%;
        display: block;
        box-sizing: border-box !important;
        padding: 0 !important;
        margin: 0 !important;
        background-image: url(${PlayNowImg});
        background-size: 100px;
        background-position: center;
        background-repeat: no-repeat;
        font-size: 1rem;
        font-weight: 800;
        cursor: pointer;
        text-align:center;
        background-color: ${
          props.theme.skin === "abc"
            ? "#940521"
            : props.theme.skin === "cac"
            ? "var(--playLive)"
            : props.theme.skin === "hyc" || props.theme.skin === "yak"
            ? "var(--bgColorABC)"
            : "var(--brandcolor)"
        };
        color: #fff;
        }
      
      `;
    }
    /* if (props.theme.skin === "nbc" && isDesktop) {
      return `
      :hover {
        background-image: url(${PlayNowImg});
        background-size: 100px;
        background-position: center;
        background-repeat: no-repeat;
      }
      `;
    } */
  }}
`;

export const HoverGamePar = styled.p`
  width: 304px;
  position: absolute;
  text-align: center;
  margin-left: -152px;
  margin-top: 30px;
  font-size: 1rem;
  font-weight: 700;
  color: #fff;

  @media (max-width: 1366px) {
    margin-top: 20px;
  }
`;

export const GameDetailWrapper = styled.div.attrs((props) => ({
  className: `${
    props.sb ? "" : "vh-100"
  } d-flex align-items-center justify-content-center`,
}))``;

export const GameSpinner = styled.div.attrs({
  className: "spinner-border",
})`
  color: var(--brandcolor);
`;

export const GameContent = styled.div.attrs({
  className: " d-flex",
})`
  /* max-height: ${({ extraHeight }) => !extraHeight && "100vh"}; */
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: ${({ theme }) =>
    theme.skin === "cac" || theme.skin === "hyc" ? "transparent" : "black"};
  background-image: ${({ theme, img }) =>
    `url(//dui95pyok1n5r.cloudfront.net/${theme.skin}/backgrounds${
      theme.skin !== "abc" ? "-v2" : ""
    }/${img})`};
`;

export const StyledGameWrapper = styled.div`
  background-color: var(--gamebg);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: ${isDesktop ? "1rem" : ""};

  .game-info {
    display: flex;
    color: var(--footerTextColor);
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--footerTextColor);
      text-align: center;
    }
  }
`;

export const StyledGameFlex = styled.div.attrs({
  className: "d-flex content-top justify-content-end",
})`
  color: #fff;
  width: 100%;
  margin: 130px auto 0 auto;
  text-align: center;
  height: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "30px !important"
      : "20px !important"};
  width: ${({ pageWidth }) => pageWidth} !important;
`;

export const QuickDeposit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn {
    min-height: 60px;
  }
  .btn-first {
    text-transform: uppercase;
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 900;
  }
`;

export const GameCommands = styled.div`
  ${(props) => {
    if (
      props.theme.skin === "abc" ||
      props.theme.skin === "nbc" ||
      props.theme.skin === "yet" ||
      props.theme.skin === "fun" ||
      props.theme.skin === "yak" ||
      props.theme.skin === "rac"
    ) {
      return `
        background-color: var(--iconbulbABC);
        border-radius: 5px 5px 0 0;
      `;
    }
  }}
  svg {
    width: 30px;
    padding: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "5px"
        : "0 0 10px 0"};
    cursor: pointer;
    path:nth-of-type(2) {
      fill: ${({ theme }) =>
        theme.skin === "abc" ? "var(--switchfill)" : "var(--switchfill)"};
      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
    }
  }
`;

export const GameFullscreanWrapper = styled.div`
  width: ${({ pageWidth }) => pageWidth};
  height: ${({ pageHeight }) => pageHeight};
  ${(props) => {
    if (props.mobile) {
      return `
      background: black;
      position: fixed;
      top:0;
      left:0;
      bottom:0;
      right:0;
      margin: 0;
      padding: 0;
      iframe {
        width: 100% !important;
        height: 100% !important;
      }
      `;
    } else if (props.fullscreen) {
      return `
      object-fit: contain;
      position: fixed !important;
      top: 0px !important;
      right: 0px !important;
      bottom: 0px !important;
      left: 0px !important;
      box-sizing: border-box !important;
      min-width: 0px !important;
      max-width: none !important;
      min-height: 0px !important;
      max-height: none !important;
      width: 100% !important;
      height: calc(100% - 21px) !important;
      transform: none !important;
      margin: 0px !important;
      `;
    } else {
      return `
        align-self: center;
        max-height: calc(100vh - 200px);`;
    }
  }}
`;

export const GamePanelSE = styled.div.attrs({
  id: "box",
})`
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1003;
  top: 0;
  left: 0;
  width: 70px;
  height: 100px;
  justify-content: space-around;
  align-items: center;
  position: fixed;

  img {
    max-width: 95%;
    height: 20px;
    max-height: 100%;
    width: auto;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const GamePanelHeader = styled.button.attrs({
  id: "panelHeader",
})`
  width: 100%;
  text-align: center;
  //height: 10px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  border: none;

  img {
    width: 15px;
  }
`;

/* export const GamePanelHeader = styled.div`
  width: 10px;
  //height: 10px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
`; */

export const StyledSELink = styled.a`
  background: white;
  margin: 1px;
  display: flex;
  padding: 3px;
  height: 100%;
`;

export const GameCasinoTimeWrapper = styled.div.attrs({
  className: "d-flex justify-content-center",
})`
  width: 100%;
  /* height: 50px; */
  //text-transform: uppercase;
  font-size: 12px;
  color: var(--footerh);
  padding: 0 30px;
  margin-top: 10px;
  div {
    display: flex;
    margin: 0 15px;
    svg {
      margin-left: 15px;
    }
  }
  .swe img {
    height: 30px;
    margin-left: 15px;
  }
`;

export const GameAlignCenter = styled.div.attrs((props) => ({
  className: props.swe ? "align-self-center ml-auto p-2" : "align-self-center",
}))`
  ${(props) => {
    if (props.swe) {
      return `
      img {
        height: 30px;
        margin-left: 15px;
      }
      `;
    }
  }}
`;

export const SidepanelButton = styled.div`
  position: relative;
  top: ${({ theme }) => (theme.skin === "plc" ? "-20px" : "0")};
  left: ${({ theme }) =>
    theme.skin === "plc" ? "calc(100% - 120px)" : "calc(100% - 50px)"};
  text-align: right;
  float: right;
  width: 30px;
  padding: 0;

  :hover {
    cursor: pointer;
  }
`;

export const StyledDoubleArrowsLeft = styled(DoubleArrowsLeft)`
  fill: var(--acolor);
`;

export const StyledDoubleArrowsRight = styled(DoubleArrowsRight)`
  transform: rotate(180deg);
  fill: var(--acolor);
`;

export const FullPageOverlay = styled.div`
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
  display: ${({ gameWindow }) => gameWindow && "flex"};
  align-items: ${({ gameWindow }) => gameWindow && "center"};
  justify-content: ${({ gameWindow }) => gameWindow && "center"};
`;

export const IngameDeposit = styled.div`
  background-color: var(--bgcolor);
  z-index: 400;
  width: auto;
  margin: auto;
  position: fixed;
  padding: ${isMobile ? "" : "0 20px 0 20px"};
  min-width: ${isMobile ? "90vw" : "600px"};
  overflow: ${isMobile ? "auto" : ""};
  height: ${({ isLandscape }) => isLandscape && isMobileOnly && "280px"};
  max-height: ${isMobile ? "90vh" : ""};
  border-radius: ${({ theme }) => theme.skin !== "rac" && "10px"};

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "cac" || theme.skin === "hyc"
        ? "#AAA2A5"
        : theme.skin === "rac"
        ? "var(--scollbarCac)"
        : "var(--footerTextColor)"};
    border-radius: 5px;
    border: 0px solid var(--scrollbarBG);
  }

  select {
    padding: ${isMobileOnly && "0"};
  }

  button {
    /* height: 55px; */
  }

  svg {
    position: absolute;
    right: 0px;
    top: 0px;
    fill: var(--tabletext);
    cursor: pointer;
    z-index: 9;
    border: none;
    width: 25px;
    height: 25px;
  }

  input,
  select {
    height: ${isMobile && "40px"};
  }
  .btn {
    min-width: ${({ theme }) => isMobile && theme.skin === "rac" && "100px"};
  }
  form {
    margin-bottom: ${isMobile && "0 !important"};
    margin-top: ${isMobile && "0 !important"};
  }

  @media (max-width: 880px) {
    width: initial;
  }
`;

export const SidePanelWrapper = styled.div`
  z-index: 100;
  background-color: ${({ theme }) =>
    theme.skin === "abc"
      ? "var(--mainDescriptionABC)"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "var(--gamebg)"
      : "var(--sideMenu)"};

  /* -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; */
  &-top,
  &-bottom {
    color: #fff;
    height: 100px;
    align-items: center;
  }

  width: ${({ isOpened }) => (isOpened ? "320px" : "0px")};
  position: ${({ isOpened }) => (isOpened ? "relative" : "")};
  display: ${({ isClosed }) => (isClosed ? "none" : "")};
  flex: ${({ isClosed }) => (isClosed ? "1" : "")};
  padding: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "20px 20px 20px 0"};

  table {
    table-layout: fixed;
    width: 100%;
    tr {
      height: 60px;
      border-bottom: "1px solid rgba(255, 255, 255, 0.13)";
      th {
        font-size: 0.7rem;
        color: ${({ theme }) => (theme.skin === "nbc" ? "#977ea2" : "#fff")};

        font-weight: 600;
        .flag {
          margin-left: 10px;
        }
        a {
          text-align: center;
          text-decoration: none;
          color: ${({ theme }) => (theme.skin === "nbc" ? "#977ea2" : "#fff")};
        }
        &.win {
          font-weight: 800;
          text-align: right;
          padding-right: 10px;
        }
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(3) {
          width: 25%;
          text-align: center;
          font-weight: 900;
        }
        &:nth-child(2) {
          text-align: center;
        }
      }
    }
  }
`;

export const SidePanelVerticalExpand = styled.div`
  width: 40px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left;
  background-color: transparent;
  position: absolute;
  background-size: cover;
  left: -30px;
  background-image: ${({ theme }) =>
    theme.current === "light"
      ? `url(${ExpandMenuLight})`
      : `url(${ExpandMenuDark})`};
`;

export const SidePanelDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 30px;
  border-radius: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "30px"};
  background-color: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "hyc") &&
    "var(--sideMenu)"};
`;

export const SidePanelTop = styled.div.attrs({
  /* className: "mt-3", */
})`
  color: #fff;
  height: 200px;
  align-items: center;
  /* position: ${({ theme }) => theme.skin === "abc" && "absolute"}; */
  display: ${({ isClosed }) => (isClosed ? "none" : "")};

  .tab-winner {
    padding: 0.75rem 1.75rem 0.75rem 0.75rem;
    cursor: pointer;
  }
`;

export const SidePanelToogleSearch = styled.div.attrs({
  className: "align-self-center align-items-center",
})`
  padding: 40px 0;

  svg {
    vertical-align: bottom;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SidePanelSelect = styled.div.attrs({
  className: "col-5 flex-nowrap",
})`
  padding: 0 !important;
  margin-top: 0px;
  :hover {
    color: #fff;
  }
  display: ${({ hideonsearch }) => (hideonsearch ? "none" : "")};
`;

export const CustomSelect = styled.select.attrs({
  className: "custom-select",
})`
  font-weight: bold;
  color: #fff !important;
  padding: 0;
  font-size: 0.8rem;
  cursor: pointer;
  background-size: 15px;
  padding: 0 !important;
  width: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun"
      ? "250px"
      : "180px !important"};
  text-transform: capitalize;
  option {
    background-color: ${({ theme }) =>
      theme.skin === "abc"
        ? "var(--bgColorABC)"
        : theme.skin === "fun"
        ? "var(--inputbgABC)"
        : "var(--customInputColor)"};
    color: ${({ theme }) =>
      theme.skin === "abc" ? "#0554B3" : "var(--acolor)"};
  }
`;

export const TabWinner = styled.div.attrs({
  className: "text-uppercase",
})`
  padding: 0.75rem 1.75rem 0.75rem 0.75rem;
  cursor: pointer;
`;

export const SidePanelTable = styled.div.attrs((props) => ({
  className: props.showAll ? "mt-5 overflow-auto" : "mt-3 overflow-hidden",
}))`
  position: relative;
  height: auto;
  scrollbar-width: thin;
  /* scrollbar-color: var(--thumbBG) var(--scrollbarBG); */

  table {
    display: inline-table;
    flex-grow: 1;
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "cac" || theme.skin === "hyc"
        ? "#AAA2A5"
        : theme.skin === "rac"
        ? "var(--scollbarCac)"
        : "var(--footerTextColor)"};
    border-radius: 5px;
    border: 0px solid var(--scrollbarBG);
  }
  table {
    table-layout: fixed;
    //margin: 0 30px;
    width: 100%;
    tr {
      height: 60px;
      //line-height: 60px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.13);
      th {
        font-size: 0.7rem;
        color: ${({ theme }) => (theme.skin === "nbc" ? "#977ea2" : "#fff")};

        font-weight: 600;
        .flag {
          margin-left: 10px;
        }
        a {
          text-align: center;
          text-decoration: none;
          color: ${({ theme }) => (theme.skin === "nbc" ? "#977ea2" : "#fff")};
        }
        &.win {
          font-weight: 800;
          text-align: right;
          padding-right: 10px;
        }
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(3) {
          width: 25%;
          text-align: center;
          font-weight: 900;
        }
        &:nth-child(2) {
          text-align: center;
        }
      }
    }
  }
`;

export const SidePanelOverley = styled.div`
  ${(props) => {
    if (props.theme.current === "dark") {
      return `
          content: ' ';
          position: absolute;
          left: 0;
          right: 0;
          height: 120px;
          bottom: 100px;
          /* background: linear-gradient(
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 1)
          ); */
          z-index: 1;
      `;
    } else {
      return `
      /* background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 1) */
      )`;
    }
  }}
`;

export const SidePanelBottom = styled.div.attrs({
  className: "mt-auto",
})`
  cursor: pointer;
  color: #fff;
  height: 100px;
  align-items: center;
`;

export const SidePanelRegister = styled.div`
  padding-top: 30px;
  color: ${({ theme }) => theme.skin === "abc" && "#0554B3"};
  a {
    color: #fff;
    float: right;
  }
`;

export const TournamentInfoDiv = styled.div.attrs({
  className: "text-center text-uppercase pt-3",
})`
  font-size: 0.8rem;
`;

export const GridyGameWrapper = styled.div.attrs({
  className: "griddy grid-container-opened",
})``;

export const SidePanelSearchWrapper = styled.div`
  width: 100%;
  min-height: ${({ theme }) => theme.skin !== "yak" && "50px"};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => (theme.skin === "cac" ? "10px" : "30px")};
  background-color: ${({ theme }) => theme.skin === "yak" && "var(--bgcolor)"};
  border-radius: ${({ theme }) => theme.skin === "yak" && "20px"};

  input {
    border: ${({ theme }) =>
      (theme.skin === "cac" || theme.skin === "hyc") && "none !important"};
  }
  .icon {
    margin: 0;
  }
  .list-group {
    a {
      height: 50px !important;
      background-color: ${({ theme }) =>
        theme.skin === "yak" && "var(--bgcolor)"};
    }
  }

  div {
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow-x: hidden;
  }
  label {
    width: 100%;
    background-color: transparent !important;
    padding: ${({ theme }) => theme.skin === "yak" && "0 5px"};
  }
  input.form-control {
    height: 40px !important;
    background-color: transparent !important;
    border-bottom: ${({ theme }) => theme.skin === "plc" && "1px solid #fff"};
    border-radius: ${({ theme }) =>
      theme.skin === "cac" || theme.skin === "hyc" ? "30px" : "0 !important"};
    padding: ${({ theme }) =>
      theme.skin === "cac" || theme.skin === "hyc"
        ? "5px"
        : theme.skin === "yak"
        ? "10px"
        : "0 !important"};
  }
  input[type="text"] {
    color: #fff !important;
  }
  #search-no-results {
    height: 45px;
    text-align: center;
    padding: 10px;
    background-color: ${({ theme }) =>
      theme.skin === "yak" && "var(--bgcolor)"};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    border-bottom: 1px solid #fff !important;
  }
  input[type="text"]::selection {
    background-color: var(--bgcolor);
  }
`;

export const IFrame = styled.iframe`
  ${(props) => {
    if (props.fullscreen) {
      return `
      object-fit: contain;
      position: fixed !important;
      top: 0px !important;
      right: 0px !important;
      bottom: 0px !important;
      left: 0px !important;
      box-sizing: border-box !important;
      min-width: 0px !important;
      max-width: none !important;
      min-height: 0px !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      transform: none !important;
      margin: 0px !important;
`;
    }
  }}
`;

export const CarouselProviderWrapper = styled.div`
  width: 150px;
  height: 100px;
  text-align: center;
  border-radius: ${({ theme }) =>
    theme.skin === "fun" || theme.skin === "yak" ? "20px" : "0.5rem"};
  margin: 0 auto;
  background: ${({ theme }) =>
    theme.skin === "rac" &&
    "linear-gradient(to bottom right, #fefb00, #e7b000)"};
  background-color: ${({ theme, themes }) =>
    themes
      ? ""
      : theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc"
      ? "var(--bgColorABC)"
      : theme.skin === "pub"
      ? "#1D1D1D"
      : "var(--playLive)"};
  /* background-color: red; */

  img {
    padding: 20px;
    width: 150px;
    height: 100px;
    margin: 0 auto;
  }
  &:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1); /* Safari & Chrome */
    -moz-transform: scale(1.1); /* Firefox */
    -ms-transform: scale(1.1); /* Internet Explorer */
    -o-transform: scale(1.1);
    transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    -moz-transition: transform 0.2s; /* Firefox */
    -ms-transition: transform 0.2s; /* Internet Explorer */
    -o-transition: transform 0.2s;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    width: 110px;
    height: 65px;
    img {
      width: 110px;
      height: 65px;
      padding: 10px;
    }
  }
`;

export const StyledMaterialGrossIcon = styled.span`
  &:after {
    margin-left: 7px;
    font-family: "Material Icons";
    content: "swap_vert";
    vertical-align: bottom;
  }
`;
export const StyledMaterialTimelapseIcon = styled.span`
  &:after {
    margin-left: 7px;
    font-family: "Material Icons";
    content: "timelapse";
    vertical-align: bottom;
  }
`;

export const GameSessionStatsContainer = styled.div.attrs({
  className: "d-flex justify-content-between",
})`
  background-color: black;
  color: white;
  padding: 0 10px;
`;

export const AlignCategoryTitle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

/* export const SportsBookWrapper = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "abc" || props.theme.skin === "nbc" ? "pb-5" : "mb-5",
}))`
  background-color: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc"
      ? "var(--mainDescriptionABC)"
      : "var(--lobbybg)"};
`;

export const SportsBookCategoriesWrapper = styled.div`
  padding: ${isMobileOnly ? "0" : "40px"};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc"
      ? "var(--mainDescriptionABC)"
      : "var(--lobbybg)"};
  & > div {
    width: ${({ theme }) =>
      theme.skin === "pub" && isMobileOnly ? "" : "1600px"};
    height: auto;
    max-width: 100%;

    @media (max-width: 768px) {
      padding: ${isMobileOnly ? "0px 20px 0 20px" : "10px 20px 0 20px"};
    }
`; */

export const DepositInGameWrapper = styled.button`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 15px;
  border-radius: 0px 10px 10px 0;
  background-color: var(--inGameDepositButtonBg);
  color: ${({ theme }) =>
    theme.skin !== "rac" &&
    theme.skin !== "cac" &&
    theme.skin !== "hyc" &&
    "#fff"};
  font-weight: ${({ theme }) =>
    theme.skin === "rac" || theme.skin === "fun" || theme.skin === "pub"
      ? "500"
      : "600"};
  font-size: ${({ theme }) =>
    theme.skin !== "rac" &&
    theme.skin !== "cac" &&
    theme.skin !== "hyc" &&
    theme.skin !== "pub" &&
    "1rem"};
  text-transform: capitalize;
  border: none;
`;

export const DepositInGameBox = styled.div.attrs({
  id: "box-deposit",
})`
  left: ${({ show }) => (show ? "0px" : "-71px")};
  display: flex;
  flex-direction: column;
  z-index: 1003;
  top: 0;
  transform: translateX(0px) translateY(80svh) translateZ(0px);
  justify-content: space-around;
  align-items: center;
  position: fixed;
  transition-property: left;
  transition-duration: 300ms;
  transition-delay: 300ms;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0px 10px 10px 0;

  img {
    height: 20px;
    padding: 2px 0;
  }
`;

export const CategorySearchWrapper = styled.div`
  margin-bottom: 0.5rem;
  input {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: var(--scrollbarBG);
    border: none;
    font-weight: bold;
    background-image: linear-gradient(
      to right bottom,
      rgb(254, 251, 0),
      rgb(231, 176, 0)
    );
  }
`;

export const LobbyTitle = styled.h3`
  /* text-align: left;
  max-width: 150px;
  position: relative;
  top: -30px; */
  text-transform: uppercase;
`;

export const LobbyNavgationTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 2rem 0 2rem;
  div {
    text-align: right;
  }
  /* align-items: center; */
`;

export const LobbyNavigationWrapper = styled.h3`
  width: 100%;
  height: 45px;
  position: relative;
  max-width: 1600px;
  top: -45px;
  padding: 0 2rem;

  p {
    border: 1px solid #979797;
    padding: 0.5rem 2rem;
    border-radius: 1.5rem;
  }
`;
