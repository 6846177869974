import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FooterButton,
  StyledFooterText,
} from "../styledComponents/StyledFooter";
import { StyledMaterialIcon } from "../styledComponents/StyledHeader";

const FooterText = ({ skin, companyInfo }) => {
  const [expand, setExpand] = useState(false);
  const [expandIcon, setExpendIcon] = useState(false);
  const { t } = useTranslation("");
  //console.log(pages);
  const handleExpend = (e) => {
    e.preventDefault();
    setExpand(!expand);
    setExpendIcon(!expandIcon);
  };

  return (
    <>
      <StyledFooterText
        expand={expand}
        companyInfo={companyInfo}
        dangerouslySetInnerHTML={{
          __html: t(
            companyInfo ? "label_company_info_footer_text" : "label_footer_text"
          ),
        }}
      >
        {/* <p>
          Pub casino offers a wide range of different live casino tables with
          real dealers and online casino games including live roulette, live
          blackjack, live baccarat, live game shows, video slots, classic slots,
          slingo and video poker. And if you are still not impressed, you can
          try your luck at plenty of games containing a local or progressive
          jackpot that can reach millions of pounds. All our games are developed
          by market leading vendors. Novomatic, Blueprint, Evolution, Netent,
          BigTimeGaming and IGT just to name a few. We are constantly on the
          hunt for new games, categories, and vendors to expand our range of
          games with innovating and the latest games to ensure the best possible
          experience and amusement for our customers.
        </p>
        <p>
          <br />
          Pub Casino is owned and operated by L&amp;L Europe Ltd, Northfields
          App 7, Vjal Indipendenza, Mosta, MST9026, Malta - all rights reserved
          &copy;. L&amp;L Europe Ltd is licensed and regulated by the MGA under
          license number: MGA/B2C/211/2011.
        </p>
        <p>
          <br />
          L&amp;L Europe Ltd is licensed by the British Gambling Commission
          under license number: 38758 for customers resident in Great Britain.
        </p>
        <p>
          <br />
          This is a real money gambling site. Gamble responsibly and only bet on
          what you can afford. For help and support with gambling addiction
          contact Be Gamble Aware on +44 (0)808 8020 133 or visit{" "}
          <a href="www.begambleaware.org">www.begambleaware.org</a>. More
          information can be found on our Responsible Gaming page.
        </p>
        <p>Gambling can be addictive. Please play responsibly.</p> */}
        {/*  <div className="col-xl">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum
          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
          irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum."
        </div> */}
        {/*  <div className="col-xl">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum
          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
          irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum."
        </div> */}
      </StyledFooterText>
      {skin !== "rac" && !companyInfo && (
        <FooterButton onClick={handleExpend}>
          {t("label_expand", "Expand")}
          <StyledMaterialIcon as="span" icon={expandIcon}></StyledMaterialIcon>
        </FooterButton>
      )}
    </>
  );
};

export default FooterText;
